import React, { useContext } from 'react';
import { useEffect } from 'react';
import { Link, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import socket from '../../soket/Socket';
import { authcontext } from './AuthContext';

const Private = () => {
    const {user} = useContext(authcontext)
    const nav = useNavigate()
    const location = useLocation()
    useEffect(()=>{
        console.log('private')
        if(user){
            if(!user.io){
                console.log('io')
                const so = socket.connect()
                console.log(so)
                user.io = true
            }
            if(location.pathname === "/" || location.pathname === "/home") nav('/home')
        }
        return ()=>{socket.disconnect()}
        
    },[])
    function logout(){
        fetch('https://jeux-api.edo.ovh/logout',{
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          method : 'post',
          credentials: 'include'  
      }).then((res)=>{
        if(res.ok){
          return res.json()
        }
      }).then((json)=>{
        console.log(json)
        window.location.reload()
      })
      }

    if(!user){
        return <Navigate to='/login'/>
    }

    return (
        <div>
            <nav className='flex  nav_bar'>
                <Link to='/home'><img src="/home_FILL0_wght100_GRAD0_opsz40.png" alt='logo' /></Link>
                <img onClick={()=>{logout()}} src="/logout_FILL0_wght400_GRAD0_opsz40.png" alt='logo' />
             </nav>
            <Outlet></Outlet>
        </div>
    );
};

export default Private;