
import React, { useCallback } from "react"
import { useEffect } from "react"
import { useContext } from "react"
/* import { useRef } from "react" */
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import MheuScorerow from "../../component/6quiprend/MheuScorerow"
import Alert from "../../component/Alert"
import { authcontext } from "../../contexte/auth/AuthContext"
import { mheupartiecontext } from "../../contexte/auth/MheuPartieContext"
import { mesageContext } from "../../contexte/mesage/MesageContext"
import socket from "../../soket/Socket"
export default function MheuScore() {
    let i
    const [mode,SetMode] = useState(false)
    const nav = useNavigate()
    const [Un, SetUn] = useState(0)
    const [Deux, SetDeux] = useState(0)
    const [Trois, SetTrois] = useState(0)
    const [cinq, Setcinq] = useState(0)
    const [Septs, SetSepts] = useState(0)
    /* const [Joueurs, SetJoueurs] = useState([]) */
    const {Mesage, setMesage} = useContext(mesageContext)
    const { partie, joueurs, setJoueurs, setPartie, loading } = useContext(mheupartiecontext)
    const { user } = useContext(authcontext)
    const [Manche, SetManche] = useState(0)
    const [sortjoueur, SetSortjouer] = useState(undefined)
    const [loading2, setLoding2] = useState(true)

    useEffect(()=>{
        console.log(" a score")
        let copymesage = {...Mesage}
        copymesage.type = undefined
        copymesage.mesage = false
        setMesage(copymesage)
    },[])
    

    useEffect(() => {
        if(joueurs!==undefined){

            sort = joueurs.slice().sort((a, b) => a.score.total - b.score.total)
            SetSortjouer(sort)
            console.log('sort',sortjoueur)
            
            let copyJoueurs = [...joueurs]
                 i = copyJoueurs.findIndex((e) => e.id_utilisateur === user.id)
                 console.log('i',i)
                 SetMode(copyJoueurs[i].mode) 
                 console.log(mode)
        }
        setLoding2(false)
        socket.io.on('reconnect',()=>{
            socket.emit('mheu/get-info-partie', (reponce)=>{
                if(!reponce.err){
                    setPartie(reponce.partie)
                    setJoueurs(reponce.joueurs)
                    console.log(reponce.partie.statut)
                    console.log(reponce)
                }else{
                    nav("/6quiprend/rejoindre")
                }
                
            })
            /* window.location.reload() */
        })
        socket.on('mheu/update_score', (score, id_utilisateur) => {
            if (id_utilisateur != user.id) {

                console.log('update_score', score)
                let copyJoueurs = [...joueurs]
                let i = copyJoueurs.findIndex((e) => e.id_utilisateur === id_utilisateur)
                copyJoueurs[i].score.manche.push(score)
                copyJoueurs[i].score.total += score.score
                console.log('scoreN', score.N, 'manche0', Manche)
                if (score.N > Manche || Manche === undefined) {
                    console.log('upN')
                    SetManche(score.N)
                }
                setJoueurs(copyJoueurs)

            }
        })
        socket.on('mheu/fin', () => {
            nav('/6quiprend/partie/fin')
        })
        if(partie !== undefined){
            console.log('partie', partie)
            if(partie.manche > Manche){
                SetManche(partie.manche)
                console.log('manche', Manche)
            }
        }

        return () => {
            socket.off('mheu/update_score')
            socket.off('mheu/fin')
            socket.off('reconnect')
           
        }

    }, [loading,partie,joueurs])




    const calculer = useCallback(() => {
        let copyJoueurs = [...joueurs]
        let s = (Un * 1) + (Deux * 2) + (Trois * 3) + (cinq * 5) + (Septs * 7)
        console.log(s)
        let i = copyJoueurs.findIndex((e) => e.id_utilisateur === user.id)
        const scorem = {
            score: s,
            N: copyJoueurs[i].score.manche.length + 1,
        }
        copyJoueurs[i].score.manche.push(scorem)
        copyJoueurs[i].score.total += s
        setJoueurs(copyJoueurs)
        console.log('score', scorem)
        if (scorem.N > Manche) {
            SetManche(scorem.N)
        }
        let copymessage = {...Mesage}
        copymessage.mesage = `Votre score est ${Un} carte un, ${Deux} carte deux, ${Trois} carte trois, ${cinq} carte cinq et ${Septs} carte sept`
        copymessage.type = 'ok'
        setMesage(copymessage)
        socket.emit('mheu/update_score', scorem)
        SetUn(0)
        SetDeux(0)
        SetTrois(0)
        Setcinq(0)
        SetSepts(0)

    })
    const handelSelect = useCallback((e, set) => {
        const value = parseInt(e.target.value)
        console.log('value', value)
        set(value)
        console.log('un', Un)
        console.log('deux', Deux)
        console.log('trois', Trois)
        console.log('cinq', cinq)
        console.log('setp', Septs)
    })
    const handelinmput = useCallback((e) => {
        const value = parseInt(e.target.value)
        console.log('value', value)
        SetUn(value)
        console.log('un', Un)
    })
    let tab = []
    console.log('manche1', Manche)
    for (let i = 0; i < Manche; i++) {
        console.log('for', i)
        tab.push(<td key={'M' + i}>M{i + 1}</td>)
    }
    let sort = undefined
    
    console.log('sort',joueurs)
    if (loading && loading2 && partie === undefined && joueurs === undefined  ) {
        return (
            <div className="loading">
                LOADING
            </div>
        )
    } else if(sortjoueur === undefined){
        return (
            <div className="loading">
                LOADING
            </div>
        )
    }else {
        return (

            <div className="score">
                <Alert mesage={Mesage}></Alert>
                <div className="partie">
                    <table>
                        <thead>
                            <tr>
                                <td>Joueur</td>
                                {tab}
                                <td>Total</td>
                            </tr>
                        </thead>
                        <tbody>
                            {sortjoueur.map((joueur) => (
                                <MheuScorerow key={joueur.score.id_score} joueur={joueur} manche={Manche} />
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="point flex">
                    {mode ? <div>
                        <label htmlFor="score">Marquer votre score</label>
                        <input id="score" value={Un} type='number' onChange={(e)=>{handelinmput(e)}}></input>
                    </div> :
                    
                        <div className="point flex">

                            <div className="tete_beauf flex">

                                <label htmlFor="un">Un</label>
                                <select id='un' value={Un} onChange={(e) => { handelSelect(e, SetUn) }}>
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30">30</option>
                                    
                                </select>
                            </div>
                            <div className="tete_beauf flex">

                                <label htmlFor="Deux">Deux</label>
                                <select id='Deux' value={Deux} onChange={(e) => { handelSelect(e, SetDeux) }}>
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    
                                    
                                </select>
                            </div>
                            <div className="tete_beauf flex">

                                <label htmlFor="Trois">Trois</label>
                                <select id='Trois' value={Trois} onChange={(e) => { handelSelect(e, SetTrois) }}>
                                <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    
                                </select>
                            </div>
                            <div className="tete_beauf flex">

                                <label htmlFor="Cinq">Cinq</label>
                                <select id='Cinq' value={cinq} onChange={(e) => { handelSelect(e, Setcinq) }}>
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>
                            </div>
                            <div className="tete_beauf flex">

                                <label htmlFor="Sept">Sept</label>
                                <select id='Sept' value={Septs} onChange={(e) => { handelSelect(e, SetSepts) }}>
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                </select>
                            </div>
                        </div>
                    }

                </div>
                    <div>
                        <button className="calculer" onClick={() => { calculer() }}>Calculer</button>
                    </div>
            </div>
        )

    }




}

/* export default MheuScore; */